frappe.provide('frappe.ui.form.LinkSelector')

frappe.ui.form.LinkSelector = class CustomLinkSelector extends frappe.ui.form.LinkSelector {
	make() {
		var me = this;
		this.start = 0;
		this.user_search_filters = [];

		this.dialog = new frappe.ui.Dialog({ // TODO: override or just add filter_area field to dialog?
			title: __("Select {0}", [this.doctype == "[Select]" ? __("value") : __(this.doctype)]),
			fields: [
				{
					fieldtype: "HTML",
					fieldname: "filter_area",
					depends_on: this.doctype != "[Select]"
				},
				{
					fieldtype: "Section Break",
				},
				{
					fieldtype: "Data",
					fieldname: "txt",
					label: __("Beginning with"),
					description: __("You can use wildcard %"),
				},
				{
					fieldtype: "HTML",
					fieldname: "results",
				},
				{
					fieldtype: "Button",
					fieldname: "more",
					label: __("More"),
					click: () => {
						me.start += 20;
						me.search();
					},
				},
			],
			primary_action_label: __("Search"),
			primary_action: function () {
				me.start = 0;
				me.search();
			},
		});

		if (this.txt) this.dialog.fields_dict.txt.set_input(this.txt);

		this.dialog.get_input("txt").on("keypress", function (e) {
			if (e.which === 13) {
				me.start = 0;
				me.search();
			}
		});
		this.make_filter_area();
		this.dialog.show();
		setTimeout(()=>{
			this.search();
		}, 100);
	}
	make_filter_area() {
		var user_search_filters = frappe.get_user_settings(this.doctype)["link_selector_search_filters"] || null
		this.user_search_filters = user_search_filters ? user_search_filters["filters"] : []

		this.filter_group = new frappe.ui.FilterGroup({
			parent: this.dialog.get_field("filter_area").$wrapper,
			doctype: this.doctype,
			on_change: () => {},
		});
		if (this.user_search_filters.length) {
			this.filter_group.add_filters_to_filter_group(this.user_search_filters);
		}
	}
	search() {
		var args = {
			txt: this.dialog.fields_dict.txt.get_value(),
			searchfield: "name",
			start: this.start,
		};

		var me = this;

		if (this.target.set_custom_query) {
			this.target.set_custom_query(args);
		}

		// load custom query from grid
		if (
			this.target.is_grid &&
			this.target.fieldinfo[this.fieldname] &&
			this.target.fieldinfo[this.fieldname].get_query
		) {
			$.extend(args, this.target.fieldinfo[this.fieldname].get_query(cur_frm.doc));
		}

		console.info('args.filters: ', args.filters);

		// var filters_json = this.get_filters_json();
		// if (filters_json && Object.keys(filters_json).length !== 0) {
		// 	var filters = Object.assign({}, args.filters, filters_json);
		// 	$.extend(args, {filters: filters});
		// }

		// filters on link selector dialog
		var filters = this.get_filters() || [];

		if (args && args.filters) {
			if ($.isArray(args.filters)) {
				filters.push(...args.filters);
			} else {
				// convert args.filters json to array and build filters
				Object.keys(args.filters).map((filter) => {
					filters.forEach((f, i) => {
						// do not allow override filters applied on grid
						if (f[1] == filter) filters.splice(i, 1);
					});

					if (this.doctype == "Item" && ["customer", "supplier"].includes(filter)) {
						// NOTE: if doctype is Item then need this hack
						// since customer / supplier not handled in queries.py, if filters passed as array
						return;
					}

					// convert filter json to array and update filters
					let val = args.filters[filter];
					if ($.isArray(val)) {
						filters.push([this.doctype, filter, val[0], val[1]]);
					} else {
						filters.push([this.doctype, filter, '=', val]);
					}
				});
			}
		}

		// overwrite filters json with filters array
		$.extend(args, {filters: filters});

		console.info('args.filters: ', args.filters);

		this.save_link_selector_search_filters();

		frappe.link_search(
			this.doctype,
			args,
			function (r) {
				var parent = me.dialog.fields_dict.results.$wrapper;
				if (args.start === 0) {
					parent.empty();
				}

				if (r.values.length) {
					$.each(r.values, function (i, v) {
						var row = $(
							repl(
								'<div class="row link-select-row">\
						<div class="col-xs-4">\
							<b><a href="#">%(name)s</a></b></div>\
						<div class="col-xs-8">\
							<span class="text-muted">%(values)s</span></div>\
						</div>',
								{
									name: v[0],
									values: v.splice(1).join(", "),
								}
							)
						).appendTo(parent);

						row.find("a")
							.attr("data-value", v[0])
							.click(function () {
								var value = $(this).attr("data-value");
								if (me.target.is_grid) {
									// set in grid
									// call search after value is set to get latest filtered results
									me.set_in_grid(value).then(() => me.search());
								} else {
									if (me.target.doctype)
										me.target.parse_validate_and_set_in_model(value);
									else {
										me.target.set_input(value);
										me.target.$input.trigger("change");
									}
									me.dialog.hide();
								}
								return false;
							});
					});
				} else {
					$(
						'<p><br><span class="text-muted">' +
							__("No Results") +
							"</span>" +
							(frappe.model.can_create(me.doctype)
								? '<br><br><a class="new-doc btn btn-default btn-sm">' +
								  __("Create a new {0}", [__(me.doctype)]) +
								  "</a>"
								: "") +
							"</p>"
					)
						.appendTo(parent)
						.find(".new-doc")
						.click(function () {
							frappe.new_doc(me.doctype);
						});
				}

				if (r.values.length < 20) {
					var more_btn = me.dialog.fields_dict.more.$wrapper;
					more_btn.hide();
				}
			},
			this.dialog.get_primary_btn()
		);
	}
	get_filters() {
		if (this.filter_group) {
			// returns filter array
			return this.filter_group.get_filters().map((filter) => {
				return filter.slice(0, 4);
			});
		}
	}
	get_filters_json() { // TODO: remove
		if (this.filter_group) {
			let filters_arr = this.filter_group.get_filters()
			// convert filter array to json
			let filters = {};
			if (filters_arr.length) {
				filters_arr.forEach((arr) => {
					// HACK: passing "=" in array crashes search search_widget
					filters[arr[1]] = (arr[2] == "=" ? arr[3] : [arr[2], arr[3]]);
				});
				filters = JSON.parse(JSON.stringify(filters));
			}
			return filters;
		}
	}
	save_link_selector_search_filters() {
		return frappe.model.user_settings.save(
			this.doctype,
			"link_selector_search_filters",
			{
				filters: this.get_filters()
			}
		);
	}
};
